const setting = {
  state: {
    isMobile: false
  },
  mutations: {
    SET_MODEL: (state, id) => {
      state.isMobile = id
    }
  },

  actions: {
    // 登录
    MODEL ({ commit }, id) {
      commit('SET_MODEL', id)
    },
  }
}

export default setting
