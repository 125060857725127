import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  // login-登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  // login-注册
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/forgetPassword.vue')
  },
  {
    path: '/termsService',
    name: 'termsService',
    component: () => import('../views/termsService.vue')
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import('../views/chart/landing.vue')
  },
  {
    path: '/application_landing/:ref/:index/:title/:description',
    name: 'application_landing',
    component: () => import('../views/chart/application_landing.vue')
  },
  {
    path: '/shortcut_android',
    name: 'shortcut_android',
    component: () => import('../views/shortcut_android.vue')
  },
  {
    path: '/shortcut_ios',
    name: 'shortcut_ios',
    component: () => import('../views/shortcut_ios.vue')
  },
  {
    path: '/shortcut_windows',
    name: 'shortcut_windows',
    component: () => import('../views/shortcut_windows.vue')
  },
  {
    path: '/examples',
    name: 'examples',
    component: () => import('../views/examples.vue')
  },
  {
    path: '/examples_landing',
    name: 'examples_landing',
    component: () => import('../views/examples_landing.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index.vue'),
    redirect: '/chart',
    children: [
      {
        path: '/home',
        name: 'home',
        keepAlive: true,
        component: () => import('../views/home/home.vue')
      },
      {
        path: '/help',
        name: 'help',
        keepAlive: true,
        component: () => import('../views/home/help.vue')
      },
      {
        path: '/homeOld',
        name: 'homeOld',
        component: () => import('../views/home/homeOld.vue')
      },
      {
        path: '/chart',
        name: 'chart',
        component: () => import('../views/chart/chart.vue')
      },
      {
        path: '/application/:ref/:index/:title/:description',
        name: 'application',
        component: () => import('../views/chart/application.vue')
      },
      {
        path: '/mission',
        name: 'mission',
        keepAlive: true,
        component: () => import('../views/home/mission.vue')
      },
      {
        path: '/poster',
        name: 'poster',
        keepAlive: true,
        component: () => import('../views/home/poster.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
