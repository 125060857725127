<template>
  <RouterView></RouterView>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import store from '@/store'
import {_isMobile} from '@/utils/common'
export default defineComponent({
  setup() {
    onMounted(() => {
      if (_isMobile()) {
        console.log("mobile")
        store.dispatch('MODEL', true)
      } else {
        store.dispatch('MODEL', false)
      }
    })
  }
})
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
