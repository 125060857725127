import request from '@/utils/request'

export function userShare(){
  return request({
    url: '/appUser/userShare',
    method: 'get'
  })
}

export function userCheckIn(){
  return request({
    url: '/appUser/userCheckIn',
    method: 'get'
  })
}

export function userShareLink (sharedUserId) {
  return request({
    url: `/appUser/userShareLink?sharedUserId=${sharedUserId}`,
    method: 'get'
  })
}

export function checkWechatScan(){
  return request({
    url: '/appUser/wechat/checkWechatScan',
    method: 'get'
  })
}

export function getQrcode(){
  return request({
    url: '/appUser/wechat/getQrCode',
    method: 'get'
  })
}

export function getUserStatus () {
  return request({
    url: '/appUser/getUserStatus',
    method: 'get'
  })
}

export function notifyWechatUser(){
  return request({
    url: '/appUser/wechat/notifyWechatUser',
    method: 'get'
  })
}

export function checkWechatUserAuth(){
  return request({
    url: '/appUser/wechat/checkWechatUserAuth',
    method: 'get'
  })
}


// 登录方法
export function login (username, password) {
  return request({
    url: '/authentication/form',
    headers: {
      isToken: false,
      formData: true
    },
    method: 'post',
    data: { username, password }
  })
}
// 获取用户详细信息
export function getInfo () {
  return request({
    url: '/appUser/getUserInfo',
    method: 'get'
  })
}

//落地页获取用户详细信息
export function getInfoForLanding () {
  return request({
    url: '/chat/getUserInfoForLanding',
    method: 'get'
  })
}

// 判断手机号是否注册
export function isPhoneRegister (phone) {
  return request({
    url: `/appUser/includeUser?phone=${phone}`,
    headers: {
      isToken: false
    },
    method: 'get',
    data: data
  })
}


//忘记密码-获取验证码
export function phoneCodeForgetPassword (phone) {
  return request({
    url: `/appUser/sendForgetSMSCode?phone=${phone}`,
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
//找回密码
export function forgetPasswordLogin (data) {
  return request({
    url: '/appUser/resetPassword',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 刷新方法
export function refreshToken () {
  return request({
    url: '/talroad-auth/refresh',
    method: 'post'
  })
}



// 退出方法
export function logout () {
  return request({
    url: '/talroad-auth/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/talroad-auth/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// // 验证码-注册验证码
// export function phoneCodeRegister (phone, code) {
//   return request({
//     url: '/appUser/sendRegisterSMSCode',
//     headers: {
//       isToken: false
//     },
//     method: 'post',
//     data: { phone, code }
//   })
// }
