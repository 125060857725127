import { login, logout, getInfo, getInfoForLanding, refreshToken, phoneCode, phoneCodeLogin, getUserStatus, userShare } from '@/api/login'
// import { login, getInfo } from '@/api/login'
import { phoneCodeRegister } from '@/api/register'
import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'

import defAva from '@/assets/images/profile.jpg'
import { phoneCodeForgetPassword, getCaptcha } from '@/api/forgetPassword'
import Cookies from 'js-cookie'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: '',
    permissions: [],
    users: [],
    expiretime: '',
    residualDegree: '',
    isLogin: 0,
    checkIn: 0,
    shareTimes: 0,
    posterUrl: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER: (state, users) => {
      state.users = users
    },
    SET_EXPIRETIME: (state, expiretime) => {
      state.expiretime = expiretime
    },
    SET_RESIDUALDEGREE: (state, residualDegree) => {
      state.residualDegree = residualDegree
    },
    SET_ISLOGIN: (state, isLogin) => {
      state.isLogin = isLogin
    },
    SET_CHECKIN: (state, checkIn) => {
      state.checkIn = checkIn
    },
    SET_SHARETIMES: (state, shareTimes) => {
      state.shareTimes = shareTimes
    },
    SET_POSTERURL: (state, posterUrl) => {
      state.posterUrl = posterUrl
    }
  },

  actions: {
    SetPosterUrl({ commit, state }){
      return new Promise((resolve, reject) => {
        userShare()
            .then(res => {
              const posterUrl = res.data;
              commit('SET_POSTERURL', posterUrl)
              resolve(res)
            })
            .catch(error => {reject(error)})
      })
    },

    GetIsLoginAndToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserStatus().then(res => {
          const data = res.data
          const id = data.id
          const adminToken = data.adminToken
          const adminExpiresIn = data.adminExpiresIn
          const tokenType = data.tokenType
          const isLogin = data.isLogin
          const isFirstVisit = data.isFirstVisit
          const openid = data.openid
          console.log("isLogin in GetIsLoginAndToken.getUserStatus " + isLogin)

          commit('SET_ISLOGIN', isLogin)
          if (adminToken != undefined){
            Cookies.set("Admin-Token", adminToken, { expires: 7 })
          }
          if (openid != undefined){
            Cookies.set("openid", openid, { expires: 7 })
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登录
    Login ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          const data = res.data
          setToken(data.access_token)
          commit('SET_TOKEN', data.access_token)
          setExpiresIn(data.expires_in)
          commit('SET_EXPIRES_IN', data.expires_in)
          const isLogin = data.isLogin
          commit('SET_ISLOGIN', isLogin)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.data
          // 用户信息
          commit('SET_USER', res.data)
          const avatar = user.avatar == '' ? defAva : user.avatar
          // 头像
          commit('SET_AVATAR', avatar)
          // 用户昵称
          commit('SET_NAME', user.nickname)
          // 权限
          // Cookies.set("SET_ROLES", user.level);
          commit('SET_ROLES', user.level)
          // SET_EXPIRETIME - 会员有效期
          commit('SET_EXPIRETIME', user.expiretime)
          //residualDegree - 剩余次数
          commit('SET_RESIDUALDEGREE', user.residualDegree)

          commit('SET_CHECKIN', user.checkIn)//是否签到
          commit('SET_SHARETIMES', user.shareTimes)//分享海报次数

          // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          //   commit('SET_ROLES', res.roles)
          //   commit('SET_USER', res.user)
          //   Cookies.set("SET_ROLES", res.roles);
          // } else {
          //   commit('SET_ROLES', ['ROLE_DEFAULT'])
          // }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    //落地页获取用户信息
    GetInfoForLanding ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfoForLanding().then(res => {
          const data = res.data
          commit('SET_USER', res.data)
          const avatar = data.avatar == '' ? defAva : data.avatar
          commit('SET_AVATAR', avatar)
          commit('SET_NAME', data.nickname)
          commit('SET_ROLES', data.level)
          commit('SET_EXPIRETIME', data.expiretime)
          commit('SET_RESIDUALDEGREE', data.residualDegree)

          if(data.access_token != undefined){
            //setToken(data.access_token)
            Cookies.set("Landing-Token", data.access_token, { expires: 7 })
            commit('SET_TOKEN', data.access_token)
            setExpiresIn(data.expires_in)
            commit('SET_EXPIRES_IN', data.expires_in)
          }

          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    // // 刷新token
    // RefreshToken({commit, state}) {
    //   return new Promise((resolve, reject) => {
    //     refreshToken(state.token).then(res => {
    //       setExpiresIn(res.data)
    //       commit('SET_EXPIRES_IN', res.data)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    //
    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        // logout(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   commit('SET_PERMISSIONS', [])
        //   removeToken()
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
      })
    },

    // // 前端 登出
    // FedLogOut({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // },
    //
    // //获取注册验证码
    // GainPhoneCode({ commit}, phone) {
    //   return new Promise((resolve, reject) => {
    //     phoneCodeRegister(phone).then(() => {
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 获取注册验证码
    GainRegisterPhoneCode ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneCodeRegister(data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取忘记密码验证码
    GainForgetPhoneCode ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneCodeForgetPassword(data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取验证码图片
    getPhoneImg ({ commit }, phone) {
      return new Promise((resolve, reject) => {
        getCaptcha(phone).then((res) => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
    // //验证码登录
    // PhoneCodeLogin({ commit }, userInfo){
    //   const phone = userInfo.phone
    //   const code = userInfo.phoneCode
    //   return new Promise((resolve, reject) => {
    //     phoneCodeLogin(phone, code).then(res => {
    //       let data = res.data
    //       setToken(data.access_token)
    //       commit('SET_TOKEN', data.access_token)
    //       setExpiresIn(data.expires_in)
    //       commit('SET_EXPIRES_IN', data.expires_in)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // }
  }
}

export default user
