import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, getOpenId, setOpenId, removeToken } from '@/utils/auth'
import { _isMicroMessager } from '@/utils/common'
import { showNotify } from 'vant'
import user from '@/store/modules/user'
import { userShareLink } from '@/api/login'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/loginNew', '/registerNew', '/forgetPassword', '/termsService',
  '/shortcut_ios', '/shortcut_android', '/shortcut_windows', '/examples', '/application', '/chart', '/help']

router.beforeEach((to, from, next) => {
  NProgress.start()
  store.dispatch('GetIsLoginAndToken').then(res => {
    const isLogin = res.data.isLogin == 0 ? false : true;
    const isSharedUser = res.data.isSharedUser == 0 ? false : true;
    const currentFullUrl = window.location.href
    const regExp = /^http.+\/\?from=(\d{19}).+$/
    const sharedUserIdArray = regExp.exec(currentFullUrl)
    if (!isSharedUser && sharedUserIdArray && sharedUserIdArray.length == 2 ) {
      console.log('sharedUserId : ' + sharedUserIdArray[1])
      userShareLink(sharedUserIdArray[1]).then()
    }
    if(res.data.openId !== undefined){
      setOpenId(res.data.openId)
    }
    store.dispatch('GetInfo').then(() => {
      if(isLogin) {
        next()
      } else if (whiteList.indexOf(to.path) !== -1 || to.path.startsWith('/application')) {
        // 在免登录白名单，直接进入
        next()
      } else {
        next(`/chart?redirect=${to.fullPath}`) // 否则全部重定向到聊天页，原来是next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        NProgress.done()
      }
    }).catch(err => {
      store.dispatch('LogOut').then(() => {
        showNotify({ type: 'warning', message: err })
        next({ path: '/' })
      })
    })
  })
})

router.afterEach(() => {
  NProgress.done()
})
