const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  users: state => state.user.users,
  roles: state => state.user.roles,
  isModel: state => state.setting.isMobile,
  expiretime: state => state.user.expiretime,
  residualDegree: state => state.user.residualDegree,
  isLogin: state => state.user.isLogin,
  checkIn: state => state.user.checkIn,
  shareTimes: state => state.user.shareTimes,
  posterUrl: state => state.user.posterUrl,
}
export default getters
