import request from '@/utils/request'

// 获取验证码-注册
export function phoneCodeRegister (data) {
  return request({
    url: `/appUser/sendRegisterSMSCode?phone=${data.phone}&randomId=${data.randomId}&captchaCode=${data.captchaCode}`,
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 注册方法
export function register (data) {
  return request({
    url: '/appUser/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
