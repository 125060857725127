import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { _isMobile } from './utils/common'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { Button, Form, Field, CellGroup, Tabbar, TabbarItem, Icon, Swipe, SwipeItem, Notify, List, PullRefresh, Popup, Dialog } from 'vant'
import { NoticeBar, Col, Row, Space, NavBar, Sidebar, SidebarItem } from 'vant'
import { Cell, Tag, ConfigProvider, Toast } from 'vant'
import { Image as VanImage } from 'vant'
import 'vant/lib/index.css'
// 路由权限
import './permission' // permission control
import './assets/iconFont/iconfont.css'
require('./assets/theme/flex.less')
require('./assets/css/common.less')
require('./assets/css/mobile.less')
// if (_isMobile()) {
//   console.log(_isMobile())
//   require('./assets/css/common.less')
//   require('./assets/css/mobile.less')
//   require('./views/chart/chartMoble.less')
//   require('./views/chart/common.less')
// } else {
//   console.log(_isMobile(), 'pc')
//   require('./assets/css/common.less')
//   require('./assets/css/pc.less')
//   // require('./views/chart/chartPc.less')
//   require('./views/chart/common.less')
//   require('./views/home/common.less')
// }
createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Form)
  .use(Field)
  .use(CellGroup)
  .use(Tabbar)
  .use(Icon).use(TabbarItem).use(Swipe).use(SwipeItem).use(Notify).use(ElementPlus).use(List).use(PullRefresh).use(Popup).use(Dialog)
    .use(NoticeBar).use(Col).use(Row).use(Space).use(NavBar).use(Sidebar).use(SidebarItem)
    .use(Cell).use(Tag).use(ConfigProvider).use(VanImage).use(Toast)
    .mount('#app')
