import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const OpenId = 'openid'

const ExpiresInKey = 'Admin-Expires-In'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token, { expires: 7 })
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getOpenId () {
  return Cookies.get(OpenId)
}

export function setOpenId (openId) {
  return Cookies.set(OpenId, openId, { expires: 30 })
}

export function removeOpenId () {
  return Cookies.remove(OpenId)
}

export function getExpiresIn () {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn (time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn () {
  return Cookies.remove(ExpiresInKey)
}
