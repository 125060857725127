import request from '@/utils/request'

// 忘记密码-获取验证码
export function phoneCodeForgetPassword (data) {
  return request({
    url: `/appUser/sendForgetSMSCode?phone=${data.phone}&randomId=${data.randomId}&captchaCode=${data.captchaCode}`,
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 找回密码
export function forgetPasswordLogin (data) {
  return request({
    url: '/appUser/resetPassword',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 判断手机号是否注册过
export function includeUserLoading (phone) {
  console.log(phone)
  return request({
    url: `/appUser/includeUser?phone=${phone}`,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
// 生成图片二维码/chatapp/captcha/getCaptcha
export function getCaptcha (randomId) {
  return request({
    url: `/captcha/getCaptchaBase64?randomId=${randomId}`,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
