import { createStore } from 'vuex'
import user from './modules/user'
import getters from './getters'
import setting from '@/store/modules/setting'
const store = createStore({
  modules: {
    user,
    setting
  },
  getters
})

export default store
